<template>
  <div class="QuestionIndex">
    <template v-for="item in dataList">
      <div
        :key="item.id"
        class="small"
      >
        <div class="title">
          {{ item.name }}
        </div>
        <div class="txt" v-html="item.answer" />
      </div>
    </template>
        
    <div v-if="limit !== 999" class="more" @click="more">
      <span>点击加载更多>></span>
    </div>
  </div>
</template>

<script>
import { questionInfo } from '@/api/serve';

export default {
  name: 'QuestionIndex',

  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      classList: [],
      dataList: [],
      limit: 9,
    };
  },

  watch: {
    id: {
      immediate: true,
      async handler(newV) {
        await this.getData(newV);
      },
    },
    limit() {
      this.getData(this.id);
    },
  },


  methods: {
 
    async getData(id) {
      const params = {
        limit: this.limit,
        questionClassId: id,
      };
      const res = await questionInfo(params);
      this.dataList = res.data.records;
    },
    more() {
      this.limit = 999;
    },
  },
};
</script>

<style lang="scss" scoped>

.small {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 25px 30px;
  padding-bottom: 25px;
  border-bottom: 1px solid #eee;

  .title {
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    color: #4d4d4d;
  }
  .txt {
    font-size: 14px;
    color: rgb(63, 63, 63);
  }
}
.more {
  // width: 100%;
  cursor: pointer;
  height: 50px;
  margin: 0 30px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  color: #018835;
  
}
</style>
