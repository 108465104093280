// 首页请求接口
import request from '../utils/request';

// 我的服务下载列表
export function download(params) {
  return request({
    url: '/api/download',
    method: 'get',
    params,
  });
}

// 我的服务分类
export function serveClass(params) {
  return request({
    url: '/api/serveClass',
    method: 'get',
    params,
  });
}

// 我们的业务
export function fetchServe(params) {
  return request({
    url: '/api/ourservices',
    method: 'get',
    params,
  });
}
// 我的服务详情
export function serveInfo(params) {
  return request({
    url: `/api/serve/${params}`,
    method: 'get',
  });
}
// 问题
export function questionInfo(params) {
  return request({
    url: '/api/questionInfo',
    method: 'get',
    params,
  });
}
// 问题分类
export function questionClass(params) {
  return request({
    url: '/api/questionClass',
    method: 'get',
    params,
  });
}
