<template>
  <!-- 正文 -->
  <div v-loading="fullscreenLoading" class="serviceBox">
    <template v-for="(item, index) in downloadList">
      <div :key="item.id" class="download">
        <span>{{ item.name }}</span>
        <ElButton
          type="info"
          size="mini"
          icon="el-icon-download"
          circle
          @click="downloadFile(index)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { download } from '@/api/serve';

export default {
  name: 'DownloadIndex',
  data() {
    return {
      fullscreenLoading: false,
      downloadList: [],
    };
  },
  created() {
    this.download();
  },
  methods: {
    async download() {
      const res = await download();
      this.downloadList = res.data;
    },
    async downloadFile(index) {
      this.fullscreenLoading = true;
      const currentFile = this.downloadList[index];
      const { file, name } = currentFile;
      try {
        this.$downloadFile(file, name);
      } catch (error) {
        this.$message.error(error);
      }
      this.fullscreenLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.serviceBox {
  width: 800px;
  margin: 50px auto;
  .download {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px #eee dashed;
    margin: 10px 0;
    span {
      font-size: 15px;
    }
  }
}
</style>
