<template>
  <!-- 下载 -->
  <div class="new">
    <Banner v-bind="bannerProps" />
    <!-- 导航 -->
    <div class="topBox">
      <template v-for="(item, index) in classList">
        <div
          :key="index"
          class="btnBox"
          :class="{ active: item.component == currentTabComponent }"
          @click="changeTab(index)"
        >
          {{ item.name }}
        </div>
      </template>
    </div>
    <!-- 正文 -->
    <Component :is="currentTabComponent" />
  </div>
</template>

<script>
import Banner from '@/components/banner.vue';
import Download from '@/views/serve/Download';
import matter from '@/views/serve/Question';
import { download } from '@/api/serve';

export default {
  name: 'ServeIndex',
  components: {
    Banner,
    Download,
    matter,
  },
  data() {
    return {
      bannerProps: {
        backgroundImage: 'https://img.kcgeis.com/8910355618.jpg',
        cnTitle: '服务中心',
        cnTitleColor: '#018835',
        enTitle: ' SERVICE SUPPORT',
        enTitleColor: '#000',
      },
      id: '',
      currentTabComponent: Download,
      classList: [
        {
          name: '下载中心',
          component: Download,
        },
        {
          name: '常见问题',
          component: matter,
        },
      ],
      downloadList: [],
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(newV) {
        this.changeTab(newV.index ?? 0);
      },
    },
  },
  created() {
    this.download();
  },
  methods: {
    changeTab(index) {
      this.currentTabComponent = this.classList[index].component;
    },
    async download() {
      const res = await download();
      this.downloadList = res.data;
    },
    async downloadFile(index) {
      const currentFile = this.downloadList[index];
      const { file, name } = currentFile;
      try {
        this.$downloadFile(file, name);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: #018835 !important;
  color: #fff;
  font-weight: 800;
}
.new {
  width: 100%;

  .bannerBox {
    position: relative;
    width: 100%;
    img {
      width: 100%;
    }
    .txt {
      position: absolute;
      top: 100px;
      right: 140px;
      h2 {
        font-size: 52px;
        color: #000;
        font-weight: bold;
        text-align: right;
      }
      h3 {
        font-size: 52px;
        font-weight: bold;
        color: #018835;
        text-align: right;
      }
    }
  }

  .topBox {
    background-color: #f6f6f6;
    width: 100%;
    padding: 30px 0;
    color: #000;
    display: flex;
    justify-content: center;
    .btnBox {
      margin: 0 25px;
      width: 100px;
      font-size: 14px;
      height: 45px;
      background-color: #eee;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 15px;
      transition: all 0.3s;
      &:hover {
        cursor: pointer;
        background-color: #018835;
        color: #fff;
        font-weight: 800;
        transition: all 0.3s;
      }
    }
  }
  .serviceBox {
    width: 800px;
    margin: 50px auto;
    .download {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px #eee dashed;
      margin: 10px 0;
      span {
        font-size: 15px;
      }
    }
  }
}
</style>
