<template>
  <div class="matter">
    <template v-for="item in classList">
      <div :key="item.id" class="item">
        <p class="item-title">
          {{ item.questionClassName }}
        </p>
        <QuestionList :id="item.id" />
      </div>
    </template>
  </div>
</template>

<script>
import QuestionList from './content/QuestionList.vue';
import { questionClass } from '@/api/serve';

export default {
  name: 'QuestionIndex',
  components: {
    QuestionList,
  },
  data() {
    return {
      classList: [],
    };
  },

  async created() {
    await this.getClass();
  },
  methods: {
    async getClass() {
      const res = await questionClass();
      this.classList = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.matter {
  width: 100%;
  padding-top: 50px;
  .item {
    width: 100%;
    padding: 0 320px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 300px;
      top: 0;
      bottom: 0;
      width: 5px;
      background-color: #018835;
    }
    .item-title {
      padding-left: 10px;
      width: 100%;
      background: #ffecd6;
      color: #018835;
      font-size: 20px;
      font-weight: bold;
      line-height: 45px;
    }

    // border-left: 5px solid #018835;
  }
}
</style>
